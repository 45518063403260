/**
 * English
 */

export default {
  LOCALE: {
    ISO: {
      "639-1": "en",
      "3166-1": "GB",
      "IETF": "en-GB",
    },
    DIR: "ltr",
    CURRENCY: "Points",
    FALLBACK_CURRENCY: "Points",
    TYPEKIT_ID: "omf2qdt",
    PRICE_FORMAT: 'sv-SE',
  },
  META: {
    TITLE: "Resurs Bank",
    NAME: "Resurs Bank",
    DESCRIPTION: "Resurs Bank",
    GOOGLE_SITE_OWNER: "xyz",
  },
  COUNTRY: {
    SE: 'Sweden',
    NO: 'Norway',
    DK: 'Denmark',
    FI: 'Finland'
  },
  PRODUCT: {
    SKU: 'SKU',
    POINTS: 'Points',
    DESCRIPTION: 'Product description',
    AVAILABILITY: 'Availability',
    DELIVERY_CUSTOMER: 'Delivery to customer',
    DELIVERY_CUSTOMER_RESULT: '%days day(s)',
    INBOUND: 'Inbound',
    IN_STOCK: 'In stock',
    OUT_OF_STOCK: 'Out of stock',
    ADD_TO_CART: 'Add to cart',
    OPTION: "%attr",
    INSTANT: 'Instant',
  },
  SIDEBAR: {
    START: "Start",
    SEARCH: "Search",
    CATEGORIES: "Categories",
    BRANDS: "Brands",
    CHECKOUT: "Checkout",
    SIGN_OUT: "Sign out",
    CUSTOMER_NOT_SIGNED_IN: 'Not signed in as customer',
    CUSTOMER_SIGNED_IN: 'Signed in as customer',
    SHOP: '%country Shop',
  },
  INFOBAR: {
    START: "Start",
    SEARCH: "Search",
    CATEGORIES: "Categories",
    BRANDS: "Brands",
    CHECKOUT: "Checkout",
    SIGN_OUT: "Logout",
    SIGN_OUT_CUSTOMER: 'Sign out customer',
    CUSTOMER_NUMBER: 'AVI account number',
    SIGN_IN_MESSAGE: 'Signing in customer...',
    SIGN_OUT_MESSAGE: 'Signing out customer...',
    SIGNED_OUT_SUCCESS: 'Signed out',
  },
  WIDGET: {
    SIGN_IN_AS_CUSTOMER: "Sign in as customer",
    SIGNED_IN_AS: 'Signed in as',
    SEARCH_PRODUCT: "Search product",
    SEARCH: 'Search',
    ENTER_CUSTOMER_NUMBER: 'Enter AVI account number',
    SEARCH_LABEL_TEXT: 'What are you looking for?',
    TOOLTIP_SEARCH: "Search for a specific product or brand.",
    TOOLTIP_SIGN_IN_CUSTOMER: "Enter the customer´s AVI-number and then choose the country/shop you would like to place an order in.",
    TOOLTIP_SIGN_OUT_CUSTOMER: "You are now logged in as the customer in the chosen store/country. To go back, press the red button below.",
  },
  BRANDSPAGE: {
    TITLE: 'Brands',
  },
  CATEGORIESPAGE: {
    TITLE: 'Categories'
  },
  CATEGORYPAGE: {
    TITLE: 'Category - %name',
    NO_RESULT: 'No products in category',
  },
  BRANDVIEW: {
    TITLE: 'Brand - %name',
    NO_RESULT: 'No products in brand',
  },
  CHECKOUTPAGE: {
    TITLE: 'Checkout',
    CART_TITLE: 'Cart',
    REMOVE: 'Remove',
    SUMMARY_TITLE: 'Summary',
    TOTAL_POINTS: 'Total',
    POINTS: 'Points',
    EMPTY_CART_TITLE: 'Cart is empty.',
    EMPTY_CART_MESSAGE: 'Add products to the cart and come back here to checkout when done.',
    ADDRESS_TITLE: 'Check address information',
    CUSTOMER_NUMBER: 'AVI account number',
    COUNTRY: 'Country',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    ADDRESS: 'Address',
    ADDRESS2: 'Address 2',
    POSTCODE: 'Post code',
    CITY: 'City',
    EMAIL: 'Email address',
    TELEPHONE: 'Phone number',
    SUBMIT: 'Submit order',
  },
  SEARCHPAGE: {
    TITLE: 'Search',
    RESULT: '(%num) results found for search "%query"'
  },
  FRONTPAGE: {
    TITLE: 'Start'
  },
  LOGIN: {
    HEADING: "Sign in",
    TITLE: "Sign in",
    FORGOT_PASSWORD: "Forgot password",
    SUBMIT: "Sign in",
    CREATE_ACCOUNT: "Create account",
    CREATE_ACCOUNT_HEADING: "Not a customer?",
  },
  SUCCESS: {
    TITLE: "Success",
    HEADING: "The order has been placed",
    ORDERNUMBER: 'Order number',
    TOTAL: 'Total sum',
    NAME: 'Name',
    ADDRESS: 'Address',
    ADDRESS2: 'Address 2',
    POSTCODE: 'Post code',
    EMAIL: 'Email',
    TELEPHONE: 'Phone',
    CITY: 'City',
    POINTS: 'Points',
    COPY: 'Copy order number',
    NEW_ORDER: 'New order',
  },
  404: {
    TITLE: "Page not found",
    HEADING: "— 404 —",
    SUBHEADING: "Page not found",
  },
  SERVER_ERROR: {
    TITLE: "Server error",
    HEADING: "Server error: 500",
    SUBHEADING: "Please come back soon!",
  },
  DROPDOWN: {
    TOGGLE: "Please choose…",
  },
  FORM: {
    PASSWORD: 'Password',
    EMAIL: 'Email',
  },
  FORM_FIELDS: {
    query: 'Query',
    accountNr: "Account number",
  },
  IMG_ALT: {
    AWARDIT_LOGO: "Awardit Logo"
  },
  VALIDATION_ERRORS: {
    REQUIRED: "Please fill in this field",
    "LENGTH_GT": "%field is too short",
    "LENGTH_LT": "%field is too long",
    "MATCH": "must match",
    "PHONE": "Invalid phone number",
    "EMAIL": "Invalid email",
    "NUMERIC": "Not a numeric value",
    "EMAIL_NOT_VALID": "The email address already exists",
  },
  ROUTES: {
  },
  STATUS_CODE: {
    /* client */
    UNKNOWN: "An unknown error has occurred. Please reload the page and try again.",
    INVALID_CODE: "Incorrect discount code.",
    NETWORK_ERROR: "The request was canceled. Please verify that you have an active internet connection or try again in a while.",
    PRODUCT_ADDED: "The product have been added to the cart.",

    /* success messages */
    "-2000": "The address is updated!",
    "-2001": "The address was created!",
    "-2002": "The address is now deleted!",

    "-2100": "The user information is now updated!",

    /* forms */
    "-6100": "Choose the one that suits you best",
    "-6101": "Choose one or more options that fit your business",



    /* product */
    1000: "The product could not be found.",

    /* cart */
    2000: "The product is out of stock.",
    2001: "Desired number is not available.",
    2002: "The product lacks attribute selection.",
    2003: "The product could not be added to the cart.",
    2004: "Please specify product and quantity.",
    2005: "Please specify product.",
    2006: "The product could not be found.",
    2007: "The product is no longer in the cart.",
    2008: "The product could not be added to the cart.",
    2009: "The product is no longer in the cart.",
    2010: "The maximum number of requested products is exceeded.",
    2011: "Something went wrong. Please contact customer service.",
    2010: "Requested number is not available in stock.",
    20010: "Requested number is not available in stock.",

    /* customer */
    3000: "You are not logged in. Please log in and try again.",
    3001: "Registration is currently suspended.",
    3002: "The email or password is missing.",
    3003: "An unknown error has occurred. Please contact customer service.",
    3004: "Customer data could not be validated.",
    3005: "There is already a user account for the e-mail address.",
    3006: "Invalid email address or password.",
    3007: "The address information could not be validated.",
    3008: "An unknown error has occurred. Please contact customer service.",
    3011: "No customer with this email address was found.",

    /* checkout */
    4000: "Incorrect payment method.",
    4001: "Incorrect payment method.",
    4002: "Incorrect delivery method.",
    4003: "Incorrect delivery method.",
    4004: "The cart could not be found.",
    4005: "The address information contains an error.",
    4006: "The address information could not be verified.",
    4007: "An unknown error has occurred. Please contact customer service.",
    4008: "Your shopping cart is empty.",
    4009: "Delivery address could not be verified.",
    4010: "Please choose delivery method.",
    4011: "Billing address could not be verified.",
    4012: "Please choose payment method.",
    4013: "We currently have problems with the chosen payment method. Please try another.",
    4014: "Payment details could not be verified.",
    4015: "An unknown error has occurred. Please contact customer service.",
    4016: "The selected payment method is not available.",
    4017: "Mandatory terms are not approved.",
    4018: "An error has occurred. Please contact customer service.",

    /* DIBS */
    6000: "Missing order number.",
    6001: "Payment information could not be verified.",
    6002: "The order cannot be found.",

    /* FORMS */
    1001: "An error occurred.",

    5000: "Incorrect email and/or password.",

    /* Klarna */
    11000: "An unknown error has occurred. Please try again, or try another payment method.",
    11001: "Missing social security number.",
    11002: "Incomplete payment method information.",
    11003: "The chosen payment method is not active.",
    11004: "Payment was denied. Please try again, or try another payment method.",

    /* Collector */
    12008: "Organization number for Collector is missing.",
    12001: "Invalid organization number.",
    12003: "Payment denied by Collector Bank. Please use another payment method or contact Collector Bank's customer service.",
    12002: "Payment denied by Collector Bank. Please use another payment method or contact Collector Bank's customer service.",
    12007: "Incorrect organization number for the specified company name.",
    12015: "Delivery instructions are too long. Max 50 characters.",

    /* Special klarna, TODO: Move to module */
    "-3201": "The mobile phone number you entered does not have the correct format. Check that all numbers are correct and try again with the following format: 07NN NN NN NN. If you still receive this message, choose another payment method to complete your purchase.",
  }
};
